import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import { Link, Outlet } from "react-router-dom";
import InitialScripts from "../script/initialScripts";
// import logo from "../assets/logo.png";
import badge1 from "../assets/trustseal1.jpg";
import badge2 from "../assets/trustseal2.jpg";
import badge3 from "../assets/trustseal3.jpg";
import badge4 from "../assets/trustseal4.jpg";
import bills from "../assets/bills.webp";
import gas from "../assets/gas.webp";
import food from "../assets/food.webp";
import rent from "../assets/rent.webp";

const FloatingCard = React.lazy(() => import("../floating-card"));

export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img loading="lazy" src={"/assets/logo.png"}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
                {
                  //<div className="logo-strip">
                  //  <div className="logoitem">
                  //    <img  loading="lazy"src={newslogo1}></img>
                  //    <img  loading="lazy"src={newslogo2}></img>
                  //    <img  loading="lazy"src={newslogo3}></img>
                  //    <img  loading="lazy"src={newslogo4}></img>
                  //    <img  loading="lazy"src={newslogo5}></img>
                  //    <img  loading="lazy"src={newslogo6}></img>
                  //  </div>
                  //</div>
                  //
                }
              </div>
            </div>
          </div>
          {/* <div className="badges-section">
            <div className="container">
              <div className="row">
                <div className="badge-headline-wrapper">
                  <h3>
                    Trusted Partners, <br />
                    Secured Promise
                  </h3>
                </div>
                <div className="badge-seal-wrapper">
                  <div className="badge-item">
                    <img src={badge1} alt=""></img>
                  </div>
                  <div className="badge-item">
                    <img src={badge2} alt=""></img>
                  </div>
                  <div className="badge-item">
                    <img src={badge3} alt=""></img>
                  </div>
                  <div className="badge-item">
                    <img src={badge4} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="stats-section">
            <div className="container">
              <div className="row">
                <div className="stats-top">
                  <div className="stats-top-left">
                    <h2>
                      Transforming Lives: The Impact of the Debt Reduction
                    </h2>
                  </div>
                  <div className="stats-top-right">
                    <p>
                      The New Debt Reduction Plan is designed to help those
                      struggling with bills. If inflation has impacted your
                      finances, this is the chance to cut up to $80,000 from
                      your debt. Act now and call our team for financial relief.
                    </p>
                  </div>
                </div>
                <div className="stats-bottom-counter">
                  <div className="stat-counter-wrapper">
                    <div className="stat-item">
                      <div className="stat-number">$43 Million</div>
                      <div className="stat-label">
                        The ripple effect of financial empowerment is clear,
                        with substantial debt relief provided to households
                        across the nation.
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">250,000+</div>
                      <div className="stat-label">
                        Many lives have been touched, with more families joining
                        every day to avail the comprehensive benefits.
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">1 in 3</div>
                      <div className="stat-label">
                        Reflecting the widespread need, a third of the
                        population qualifies for this life-enhancing program,
                        ensuring no one is left behind.
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">482 Seats</div>
                      <div className="stat-label">
                        With limited spots remaining, urgency meets opportunity
                        for those aiming to harness the program's full
                        potential.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="stats-section">
            <div className="container">
              <div className="row">
                <div className="stats-top">
                  <div className="stats-top-left">
                    <h2>Why was this debt relief program created?</h2>
                  </div>
                  <div className="stats-top-right">
                    <p>
                      Since the pandemic, prices have surged, increasing the
                      cost of essentials like food and utilities. This new
                      program aims to support families facing these financial
                      challenges and provide relief from the burden of
                      inflation.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="gap" />
            </div>
          </div>

          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h2>Satisfied Citizens Share Their Stories!</h2>
                  <img loading="lazy" src={"/assets/testimonials.png"} alt="" />
                </div>
                <div className="testimonial-content-holder">
                  <p>
                    We strive to offer you the best service, as evidenced by our
                    Trustpilot star rating.
                  </p>
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          The Debt Relief Program provided exceptional service.
                          They guided me through every step of the debt
                          reduction process and helped relieve a significant
                          financial burden. I can’t thank them enough for their
                          support.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-1.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>Floyd Miles</h3>
                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          Thanks to the Debt Reduction Program, I can now relax
                          knowing my debt is under control. The team was
                          professional and attentive, providing the peace of
                          mind I needed.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-2.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>Michael Anderson</h3>
                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          I received personalized and effective support from the
                          Debt Reduction Program. The reduction in my debt was
                          beyond my expectations, and I feel much more confident
                          about my financial future.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-3.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>David Wilson</h3>
                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          The Debt Reduction Program did a fantastic job helping
                          me reduce my debt quickly and effectively. I’m very
                          thankful for their commitment and the care they showed
                          throughout the process.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-4.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>Olivia Martinez</h3>

                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            <div className="container">
              <div className="row"></div>
            </div>
          </div> */}
          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is not an insurance or
                    operating company but connects individuals with insurance
                    providers and other affiliates. Plans are insured or covered
                    by a Medicare Advantage organization with a Medicare
                    contract and/or a Medicare-approved Part D
                    sponsor.Enrollment in the plan depends on the plan’s
                    contract renewal with Medicare. Possible options include,
                    but are not limited to Major Medical Plans, Short Term
                    Plans, Christian Health Plans, Health Sharing Plans,
                    discount cards and Fixed Indemnity Plans.Descriptions are
                    for informational purposes only and subject to change.
                    {" " + window.domain_settings.websiteName} is not affiliated
                    with any insurance plan nor does it represent or endorse any
                    plan. We do not offer every plan available in your area. Any
                    information we provide is limited to those plans we do offer
                    in your area. Please contact Medicare.gov or 1–800 MEDICARE
                    to get information on all of your options. By using this
                    site, you acknowledge that you have read and agree to the
                    Terms of Service, and Privacy Policy. Not affiliated with
                    the U. S. government or federal Medicare program. A licensed
                    agent may contact you regarding this insurance-related
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>
                      © Copyright {window.domain_settings.websiteTitle} 2023
                    </p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <React.Suspense fallback={<></>}>
        <FloatingCard />
      </React.Suspense>
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
