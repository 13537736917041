import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import logo from "../assets/logo.png";
import heroimage from "../assets/card-in-hand.svg";
import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import arrow from "../assets/arrow.svg";
import testimonial from "../assets/testimonials.png";

import InitialScripts from "../script/initialScripts";
import trustseal from "../assets/trust-seal.png";
import badge1 from "../assets/trustseal1.jpg";
import badge2 from "../assets/trustseal2.jpg";
import badge3 from "../assets/trustseal3.jpg";
import badge4 from "../assets/trustseal4.jpg";
import {
  useGeneratorQuery,
  RINGBA_STORAGE_KEYS,
  useRingba,
  useInitRingba,
} from "wecall-config-lib";
import { useNavigate } from "react-router-dom";
import { Timer } from "../App";
import QuizInitialScripts from "../script/quizInitialScript";
import { ROUTES } from "../App";

const FloatingCard2 = React.lazy(() => import("../floating-card2"));

const routes = {
  congrats: "congratsVisited",
  income: "incomeVisited",
  medicareMedicaid: "medicareMedicaidVisited",
  lander: "landerVisited",
};

export function MedicareMedicaidQuizLander({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();
  const navigate = useNavigate();

  const setValues = (value) => {
    sessionStorage.setItem("consolidate", value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ ["consolidate"]: value });
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.income))
      return navigate({
        pathname: "../" + ROUTES.income,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.medicareMedicaid, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="left-side">
        <div className="question">
          <h2>¿Quieres consolidar tu deuda y simplificar tu vida?</h2>

          <Link
            onClick={() => setValues("yes")}
            id="medicare_medicaid_yes"
            to={{ pathname: nextPageRoute, search: generatorQuery.get() }}
          >
            Sí
          </Link>

          <Link
            onClick={() => setValues("no")}
            id="medicare_medicaid_no"
            to={"https://rtrackt.com/clickout/23041/311260/?"}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

export function IncomeQuizLander({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();

  const setValues = (value) => {
    sessionStorage.setItem("10k", value);
    // storeRgbaData(RINGBA_STORAGE_KEYS.income, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ ["10k"]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="left-side">
        <div className="question">
          <h2>¿Tienes más de $10,000 en deudas de tarjetas de crédito?</h2>
          <Link
            onClick={() => setValues("yes")}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            Sí
          </Link>
          <Link
            // onClick={() => setValues("50 - +")}
            onClick={() => setValues("no")}
            to={"https://rtrackt.com/clickout/23041/311260/?"}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

export function CTAQuizLander({ headline, subtitle }) {
  const generatorQuery = useGeneratorQuery();

  useEffect(() => {
    // generatorQuery.set(window.location.search);
    sessionStorage.setItem(routes.lander, true);
  }, []);
  return (
    <>
      <div className="span-headline-wrapper">
        <h3>
          <span>Programa de Reducción de Deuda 2024</span>
        </h3>
      </div>
      <div className="headline-wrapper">{headline}</div>

      <div className="sub-hero-section">
        <div className="left-side">
          <p>{subtitle}</p>

          <div className="desktop-cta">
            <Link
              id="cta-click"
              to={{ pathname: "10k", search: generatorQuery.get() }}
            >
              Quiero que mi deuda sea eliminada
              <img src={arrow} alt=""></img>
            </Link>
            <span>¡Quedan 37 espacios disponibles, ¡actúa ahora!</span>
          </div>
        </div>

        <div className="right-side">
          <img src={heroimage}></img>
        </div>
      </div>
    </>
  );
}

export function CongratsQuizLander({ number, headline }) {
  const navigate = useNavigate();
  const { getEventId } = useEventID();

  const generatorQuery = useGeneratorQuery();

  const triggerContactEvent = () => {
    window.fbcFunc("track", "Contact", {
      eventID: getEventId(),
    });
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.medicareMedicaid))
      return navigate({
        pathname: "../" + ROUTES.medicareMedicaid,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.congrats, true);
  }, []);

  return (
    <>
      <div className="left-side">
        <div className="congrats">
          <h2>{headline}</h2>
          <p>
            Estás pre-calificado para recibir alivio de deuda. Toca el botón
            abajo para llamar y reclamarlo.
          </p>
          <a
            onClick={triggerContactEvent}
            href={"tel:" + number}
            className="call_now"
          >
            {number}
          </a>
          <p>
            Este programa tiene una capacidad extremadamente limitada, así que
            llámanos en los próximos {""}
            <Timer /> para reclamar tu lugar antes de que se le asigne a otro
            solicitante en espera.
          </p>
          <img src={trustseal} alt=""></img>
        </div>
      </div>
    </>
  );
}

function MobileNumberLoader({ setNumber }) {
  const testRingba = window.domain_settings.rinbaUser;

  let ringba = testRingba;
  const generator = ringba.user;
  const { number } = useInitRingba({
    ringbaKey: {
      key: testRingba.key,
      number: testRingba.number,
      user: testRingba.user,
    },
  });

  useEffect(() => {
    setNumber(number);
  }, [number]);
  return <></>;
}

export default function QuizLander({ setNumber, cityStateResponse }) {
  const location = useLocation();

  return (
    <>
      <QuizInitialScripts setNumber={setNumber} />
      {location.pathname.includes(ROUTES.income) ||
      location.pathname.includes(ROUTES.medicareMedicaid) ||
      location.pathname.includes(ROUTES.congrats) ||
      location.pathname.includes(ROUTES.healthInsurance) ||
      location.pathname.includes(ROUTES.phoneNumber) ? (
        <MobileNumberLoader setNumber={setNumber} />
      ) : undefined}
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img src={logo}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
                <div className="logo-strip">
                  <div className="logoitem"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="badges-section">
            <div className="container">
              <div className="row">
                <div className="badge-headline-wrapper">
                  <h3>
                    Socios Confiables,
                    <br />
                    Promesa Segura
                  </h3>
                </div>
                <div className="badge-seal-wrapper">
                  <div className="badge-item">
                    <img src={badge1} alt=""></img>
                  </div>
                  <div className="badge-item">
                    <img src={badge2} alt=""></img>
                  </div>
                  <div className="badge-item">
                    <img src={badge3} alt=""></img>
                  </div>
                  <div className="badge-item">
                    <img src={badge4} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="stats-section">
            <div className="container">
              <div className="row">
                <div className="stats-top">
                  <div className="stats-top-left">
                    <h2>
                      Transformando Vidas: El Impacto de la Reducción de Deuda
                    </h2>
                  </div>
                  <div className="stats-top-right">
                    <p>
                      El Nuevo Plan de Reducción de Deuda está diseñado para
                      ayudar a quienes luchan con sus cuentas. Si la inflación
                      ha afectado tus finanzas, esta es tu oportunidad para
                      reducir hasta $80,000 de tu deuda. Actúa ahora y llama a
                      nuestro equipo para obtener alivio financiero.
                    </p>
                  </div>
                </div>
                <div className="stats-bottom-counter">
                  <div className="stat-counter-wrapper">
                    <div className="stat-item">
                      <div className="stat-number">$43 Millones</div>
                      <div className="stat-label">
                        El efecto dominó del empoderamiento financiero es
                        evidente, con un alivio sustancial de deuda brindado a
                        hogares en todo el país.
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">250,000+</div>
                      <div className="stat-label">
                        Muchas vidas han sido impactadas, con más familias
                        uniéndose cada día para aprovechar los beneficios
                        integrales.
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">1 de cada 3</div>
                      <div className="stat-label">
                        Reflejando la necesidad generalizada, un tercio de la
                        población califica para este programa que mejora la
                        vida, asegurando que nadie quede atrás.
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">482 Plazas</div>
                      <div className="stat-label">
                        Con pocas plazas disponibles, la urgencia se une a la
                        oportunidad para quienes buscan aprovechar al máximo el
                        potencial del programa.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="stats-section">
            <div className="container">
              <div className="row">
                <div className="stats-top">
                  <div className="stats-top-left">
                    <h2>¿Por qué se creó este programa de alivio de deuda?</h2>
                  </div>
                  <div className="stats-top-right">
                    <p>
                      Desde la pandemia, los precios han aumentado,
                      incrementando el costo de necesidades básicas como
                      alimentos y servicios. Este nuevo programa tiene como
                      objetivo apoyar a las familias que enfrentan estos
                      desafíos financieros y proporcionar alivio frente a la
                      carga de la inflación.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="gap" />
            </div>
          </div>

          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h2>¡Ciudadanos Satisfechos Comparten Sus Historias!</h2>
                  <img loading="lazy" src={"/assets/testimonials.png"} alt="" />
                </div>
                <div className="testimonial-content-holder">
                  <p>
                    Nos esforzamos por ofrecerte el mejor servicio, como lo
                    demuestra nuestra calificación en Trustpilot.
                  </p>
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          El Programa de Alivio de Deuda brindó un servicio
                          excepcional. Me guiaron en cada paso del proceso de
                          reducción de deuda y me ayudaron a aliviar una carga
                          financiera significativa. No puedo agradecerles lo
                          suficiente por su apoyo. Logré reducir $26,352 de mi
                          deuda.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-1.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>Floyd Miles</h3>
                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          Gracias al Programa de Reducción de Deuda, ahora puedo
                          relajarme sabiendo que mi deuda está bajo control. El
                          equipo fue profesional y atento, brindándome la
                          tranquilidad que necesitaba. Reduje $18,765 de mi
                          deuda.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-2.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>Michael Anderson</h3>
                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          Recibí un apoyo personalizado y efectivo del Programa
                          de Reducción de Deuda. La reducción de mi deuda superó
                          mis expectativas, y me siento mucho más seguro sobre
                          mi futuro financiero. Pude reducir $32,481 de mi
                          deuda.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-3.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>David Wilson</h3>
                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          El Programa de Reducción de Deuda hizo un trabajo
                          fantástico ayudándome a reducir mi deuda de manera
                          rápida y eficaz. Estoy muy agradecido por su
                          compromiso y el cuidado que demostraron durante todo
                          el proceso. Logré reducir $21,934 de mi deuda.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-4.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>Olivia Martinez</h3>

                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            <div className="container">
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
      <React.Suspense fallback={<></>}>
        <FloatingCard2 />
      </React.Suspense>
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
